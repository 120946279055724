<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <div class="logo-and-language">
        <b-link class="bilge-logo" :to="{ path: '/' }">
          <b-img :src="brandLogo" alt="Logo" :style="`width:${systemSettings.title_image_width}px!important`" />
        </b-link>
        <locale class="locale-dropdown" />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
        <div class="app-footer">
          <app-footer />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto auth-container">
          <h1
            class="mb-2 d-none d-md-block font-weight-bolder"
            :class="{
              'text-white': skin === 'dark'
            }"
          >
            {{ systemSettings.login_title }}
          </h1>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('Login') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('LoginPlaceholder') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="login-email" v-model="username" :state="errors.length > 0 ? false : null" name="login-email" placeholder="Email" />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group :label="$t('Password')" label-for="login-password">
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- remember me -->

              <div class="media d-flex align-items-center mb-1">
                <b-form-checkbox id="login_remember_me" v-model="rememberModel" />
                <label for="login_remember_me" class="font-weight-bold text-primary mb-0">
                  {{ $t('remember_me') }}
                </label>
              </div>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="validationForm" :disabled="buttonState">
                {{ buttonState ? $t('LoggingIn') : $t('Login') }}
              </b-button>
              <div class="d-flex justify-content-end">
                <b-link :to="{ name: 'forgot-password' }">
                  <small>{{ $t('ForgotPassword') }}</small>
                </b-link>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
.auth-container {
  @media (max-width: (map-get($grid-breakpoints, lg))) {
    padding: 10rem 0;
  }
}
.logo-and-language {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
  z-index: 1;
  .bilge-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .locale-dropdown {
    display: flex;
  }
}

.app-footer {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
</style>

<script>
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue'
import AppFooter from '@/@core/layouts/components/AppFooter.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import Locale from '@/layouts/components/Locale.vue'
import { LOGIN, SET_REMEMBER_ACCOUNT, SET_REMEMBER_ME } from '@/store/services/auth-service'
import { GET_ALL_CURRENCY } from '@/store/services/currency-service'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
    AppFooter
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg')
    }
  },
  created() {
    if (this.rememberMe) {
      this.username = this.rememberUsername
      this.password = this.rememberPassword
    }
  },
  computed: {
    ...mapGetters(['buttonState', 'getSkin', 'currentUser', 'brandLogo', 'brandTitle', 'loginImage', 'appConfig/getThemeSkin', 'systemSettings', 'rememberMe', 'rememberUsername', 'rememberPassword']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.loginImage) {
        return this.loginImage
      } else {
        if (this.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      }
    },
    skin() {
      return this['appConfig/getThemeSkin']
    },
    rememberModel: {
      get() {
        return this.rememberMe
      },
      set(val) {
        this.$store.commit(SET_REMEMBER_ME, val)
      }
    }
  },
  methods: {
    validationForm() {
      const { username } = this
      const { password } = this
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch(LOGIN, { username, password }).then(response => {
            if (response.status) {
              this.$store.dispatch(GET_ALL_CURRENCY)
              if (this.rememberMe) {
                this.$store.commit(SET_REMEMBER_ACCOUNT, { username: this.username, password: this.password })
              }
              this.$router.push({ name: 'home' }).then(() => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Hoş Geldin ${this.currentUser.name} ${this.currentUser.surname}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Hesabınıza Başarıyla Giriş Yapıldı!'
                    }
                  },
                  {
                    position: 'bottom-left'
                  }
                )
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
